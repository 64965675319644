import authService from "../../../services/authService";
import { mapActions, mapGetters } from "vuex";
import * as types from "../../../store/types";

export default {
    name: "admin-navigations",
    components: {},
    props: [],
    data() {
        return {
            permissions: {},

        };
    },
    computed: {
        ...mapGetters({
            userInfo: types.GET_USER_INFO,
        })
    },
    mounted() {
        this.permissions = this.userInfo.permissions;
    },
    methods: {
        ...mapActions({

            checkIsAuth: types.ACTION_CHECK_IS_AUTH,
        }),
        logout() {
            this.loading = true;
            authService
                .logout()
                .then(() => {
                    localStorage.removeItem("accessToken");
                    localStorage.removeItem("bitrahAdminAccessToken");
                    localStorage.removeItem("bitrahAccessToken");
                    localStorage.setItem("isAuth", false);
                    this.checkIsAuth();
                    this.$router.push(`/`);
                })
                .catch(() => {
                    this.$bvToast.toast(this.$i18n.t("nav.error"),{
                        title: this.$i18n.t("toast.errorTitle"),
                        noCloseButton: true,
                        variant: "custom",
                        bodyClass:
                            "dangerBackgroundColor dangerFontColor rtl text-right",
                        headerClass:
                            "dangerBackgroundColor dangerFontColor  rtl text-right",
                        solid: false,
                    });
                })
                .finally(() => (this.loading = false));
        },
    },
};
